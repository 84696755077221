.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.header {
  background-color: #fff;
  padding: 0 20px;
  color: white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
@media all and (min-width: 480px) {
  .header {
    padding: 0 30px;
  }
}
@media all and (min-width: 640px) {
  .header {
    padding: 0 30px;
  }
}
.header .headerLogo {
  width: 100%;
  max-width: 220px;
  height: auto;
  display: inline-block;
  -ms-flex-item-align: center;
      align-self: center;
}
.header .appLogo {
  max-width: 90px;
}
.homeDescription {
  -ms-flex-positive: 1;
      flex-grow: 1;
  position: relative;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.homeDescription:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 100%;
  background: #000;
  opacity: 0.65;
}
.homeDescription .homeDescriptionTitle {
  font-family: 'OpenSans-Light', Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 1;
  letter-spacing: 0;
  line-height: 1.1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 40px;
  width: 100%;
  max-width: 320px;
  letter-spacing: 1.2px;
}
@media all and (min-width: 480px) {
  .homeDescription .homeDescriptionTitle {
    font-size: 60px;
    max-width: 470px;
  }
}
@media all and (min-width: 640px) {
  .homeDescription .homeDescriptionTitle {
    font-size: 60px;
    max-width: 520px;
  }
}
.homeDescription .homeDescriptionText {
  font-family: 'OpenSans-Light', Arial, Helvetica, sans-serif;
  font-weight: 700;
  z-index: 1;
  margin-top: 0;
  line-height: 1.5;
  font-size: 22px;
  width: 100%;
  max-width: 280px;
}
@media all and (min-width: 480px) {
  .homeDescription .homeDescriptionText {
    font-size: 36px;
    max-width: 470px;
  }
}
@media all and (min-width: 640px) {
  .homeDescription .homeDescriptionText {
    font-size: 30px;
    max-width: 510px;
  }
}
.buttonWrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: noswrap;
      flex-wrap: noswrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
  position: relative;
}
.buttonWrapper .button {
  width: 250px;
  text-align: left;
  border-style: solid;
  border-width: 3px;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.5);
  margin: 20px;
}
.buttonWrapper .button:hover {
  background: #e6e6e6;
}
.buttonWrapper .button:active {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}
.buttonWrapper .button::-moz-selection {
  background: none;
}
.buttonWrapper .button::selection {
  background: none;
}
@media screen and (max-width: 520px) {
  .buttonWrapper {
    -ms-flex-direction: column;
        flex-direction: column;
  }
}
