.infoBox {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 10px;
  padding-bottom: 10px;
  background: #fff;
  overflow-y: auto;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 90px;
}
@media all and (min-width: 480px) {
  .infoBox {
    margin-bottom: 135px;
  }
}
@media all and (min-width: 640px) {
  .infoBox {
    margin-bottom: 160px;
  }
}
.infoBox h1 {
  padding-left: 10px;
}
.infoBox h1,
.infoBox h2 {
  margin-bottom: 30px;
  color: #717171;
  font-family: 'OpenSans-Bold';
}
.message {
  display: block;
  padding-left: 10px;
}
.list {
  font-size: 18px;
}
.listItem {
  position: relative;
  padding: 15px 30px 15px 10px;
  border-bottom: 1px solid #d3d3d3;
  color: #79ada3;
  font-size: 18px;
  cursor: pointer;
}
.listItem:not(.heading):hover {
  color: #f9f9f9;
  background-color: #79ada3;
}
.listItem:not(.heading):hover:after {
  border: solid #f9f9f9;
  border-width: 0 1px 1px 0;
}
.listItem:last-child {
  border-bottom: none;
}
.listItem:after {
  content: '';
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 50%;
  border: solid #96999f;
  border-width: 0 1px 1px 0;
  padding: 4px;
  -webkit-transform: rotate(-45deg) translateY(-50%);
      -ms-transform: rotate(-45deg) translateY(-50%);
          transform: rotate(-45deg) translateY(-50%);
}
.heading {
  color: #717171;
  margin-top: 20px;
  cursor: default;
  background-color: transparent;
}
.heading:after {
  display: none;
}
.entry {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 20px;
  background: #fff;
  font-size: 18px;
  line-height: 1.5;
}
.entry img {
  max-width: 100%;
}
.entry ul {
  padding-left: 20px;
}
.entry table {
  border-spacing: 10px;
}
.entry strong + p {
  margin-top: 5px;
  margin-bottom: 30px;
}
