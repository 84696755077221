.dialogue {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
      flex-flow: column;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.8)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
  background: -o-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: default;
}
.messages {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
      flex-flow: column;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  position: relative;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-item-align: center;
      align-self: center;
  padding: 20px 70px 70px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: auto;
  overflow: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.choiceContainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-animation: showChoices 350ms;
          animation: showChoices 350ms;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.choiceContainer div[class^="answer_"] img {
  max-height: 80px;
}
.touchHint {
  padding: 35px 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 18px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
@-webkit-keyframes showChoices {
  from {
    top: 100px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@keyframes showChoices {
  from {
    top: 100px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
