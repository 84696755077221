@font-face {
  font-family: 'OpenSans';
  src: url('../../static/fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../../static/fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Light';
  src: url('../../static/fonts/OpenSans-Light.ttf') format('truetype');
}
body {
  font-family: 'OpenSans', Arial, Helvetica, sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -ms-user-select: none;
}
strong {
  font-family: 'OpenSans-Bold';
}
*:focus {
  outline: 0;
}
.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 50;
  padding: 20px;
  text-align: center;
  overflow: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.container .stepsCounter {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #717171;
}
.container .badgeContainer {
  text-align: center;
}
.container .badge {
  display: inline-block;
  width: 80%;
  height: auto;
  max-width: 400px;
}
@media all and (min-width: 480px) {
  .container .badge {
    width: 90%;
  }
}
.container .points {
  color: #df0a71;
  font-size: 25px;
  font-family: 'OpenSans-Bold';
  font-weight: 900;
  margin-bottom: 0;
}
.container .points > span {
  font-size: 46px;
}
.container .feedback {
  margin-top: 5px;
  color: #717171;
  margin-bottom: 30px;
  font-size: 18px;
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.container .feedback.show {
  opacity: 1;
}
.container .nextButton {
  background-color: #7fada3;
  cursor: pointer;
  display: block;
  width: 100%;
  border-radius: 4px;
  padding: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  -webkit-box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.container .nextButton:hover {
  background-color: #67948c;
}
@media all and (min-width: 480px) {
  .container .nextButton span {
    font-size: 24px;
  }
}
@media all and (min-width: 480px) {
  .container .nextButton span {
    font-size: 28px;
  }
}
