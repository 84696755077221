.navDots {
  margin: 30px auto;
}
@media all and (min-width: 480px) {
  .navDots {
    margin: 40px auto;
  }
}
@media all and (min-width: 640px) {
  .navDots {
    margin: 50px auto;
  }
}
