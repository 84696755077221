.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
}
.popUp {
  position: fixed;
  top: 50%;
  left: 10px;
  right: 10px;
  padding: 40px 10px 20px;
  background-color: #fff;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media all and (min-width: 480px) {
  .popUp {
    padding: 60px 10px 50px;
  }
}
.popUp h1 {
  margin: 0 0 15px;
  color: #202020;
  font-family: 'OpenSans', Arial, Helvetica, sans-serif;
  font-size: 26px;
  padding: 0 10px 5px;
  border-bottom: 1.5px solid #000;
  line-height: 1.3;
}
@media all and (min-width: 480px) {
  .popUp h1 {
    font-size: 30px;
  }
}
.popUp p {
  margin: 0;
  color: #202020;
  font-family: 'OpenSans', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  padding-left: 10px;
}
.closeButton {
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  background-color: #d3d3d3;
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.closeButton:hover {
  background: #bababa;
}
.closeButton::before,
.closeButton::after {
  content: '';
  position: absolute;
  height: 3.5px;
  width: 70%;
  top: 14px;
  left: 5px;
  background: #717171;
}
.closeButton::before {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.closeButton::after {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
