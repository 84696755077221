.container {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.container .navDots {
  margin: 30px auto 0;
  width: 45px;
}
.slider {
  position: fixed;
  bottom: 16%;
  left: 2.5%;
  padding: 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
}
.slider.sliderFadded {
  opacity: 0.8;
}
.swipeContainer {
  height: 100%;
  padding: 0 1px;
}
.swipeableSlide {
  background: #fff;
}
