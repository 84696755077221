.buttonWrapper {
  right: 20px;
}
@media all and (min-width: 480px) {
  .buttonWrapper {
    right: 40px;
  }
}
.buttonWrapper .buttonArrow {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-right: 7px;
}
@media all and (min-width: 480px) {
  .buttonWrapper .buttonArrow {
    margin-right: 10px;
  }
}
