@font-face {
  font-family: 'OpenSans';
  src: url('../../static/fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../../static/fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Light';
  src: url('../../static/fonts/OpenSans-Light.ttf') format('truetype');
}
body {
  font-family: 'OpenSans', Arial, Helvetica, sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -ms-user-select: none;
}
strong {
  font-family: 'OpenSans-Bold';
}
*:focus {
  outline: 0;
}
.buttonHintWrapper {
  position: relative;
  z-index: 6;
}
.buttonHintWrapper .buttonHintBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.buttonHintContent {
  position: fixed;
  background-color: #fff;
  bottom: 98px;
  padding: 20px 25px 30px;
}
@media all and (min-width: 480px) {
  .buttonHintContent {
    bottom: 127px;
  }
}
@media all and (min-width: 640px) {
  .buttonHintContent {
    bottom: 155px;
  }
}
.buttonHintContent:after {
  content: '';
  position: absolute;
  display: none;
  bottom: -35px;
  height: 18px;
  border-top: 18px solid #fff;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
}
@media all and (min-width: 480px) {
  .buttonHintContent:after {
    bottom: -39px;
    height: 20px;
    border-top: 20px solid #fff;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
  }
}
@media all and (min-width: 640px) {
  .buttonHintContent:after {
    bottom: -49px;
    height: 25px;
    border-top: 25px solid #fff;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
  }
}
.buttonHintContent .buttonHintTitle {
  margin: 0;
  font-family: 'OpenSans-Bold';
  color: #202020;
  margin-bottom: 10px;
  font-size: 28px;
  line-height: 1.5;
}
@media all and (min-width: 480px) {
  .buttonHintContent .buttonHintTitle {
    font-size: 34px;
  }
}
@media all and (min-width: 640px) {
  .buttonHintContent .buttonHintTitle {
    font-size: 40px;
    line-height: 1.5;
  }
}
.buttonHintContent .buttonHintdescription {
  margin: 0;
  color: #202020;
  line-height: 24px;
  font-size: 18px;
}
@media all and (min-width: 480px) {
  .buttonHintContent .buttonHintdescription {
    line-height: 36px;
    font-size: 26px;
  }
}
@media all and (min-width: 640px) {
  .buttonHintContent .buttonHintdescription {
    line-height: 44px;
    font-size: 32px;
  }
}
