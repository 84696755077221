.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.container .question {
  font-family: 'OpenSans-Bold';
  font-size: 18px;
  height: auto;
}
.container .question p {
  margin: 0;
  margin-bottom: 20px;
}
.container .question small {
  font-size: 18px !important;
  font-weight: normal !important;
  font-family: 'OpenSans';
}
@media all and (min-width: 640px) {
  .container .question small {
    font-size: 28px !important;
    line-height: 40px !important;
  }
}
.container .answerContainer {
  display: -ms-flexbox;
  display: flex;
}
.container .answers {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
}
.container .answers > div {
  display: -ms-flexbox;
  display: flex;
}
.container .answers .answer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin: 0;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0px 10px 0px;
}
@media all and (min-width: 640px) {
  .container .answers .answer {
    margin: 14px 0px 13px 0px;
  }
}
.container .answers .answer span span {
  font-size: 18px;
}
@media all and (min-width: 640px) {
  .container .answers .answer span span {
    font-size: 28px;
  }
}
.container .answers .answer > span {
  margin-bottom: 0 !important;
  -ms-flex: initial !important;
      flex: initial !important;
  cursor: pointer;
}
.container .answers .answer > span p {
  display: inline-block;
  color: #717171;
  padding-right: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
}
@media all and (min-width: 640px) {
  .container .answers .answer > span p {
    font-size: 28px;
    line-height: 40px;
  }
}
.container .answers .answer > span .button {
  display: none;
}
.container .answers .answer > span {
  -ms-flex-order: 2;
      order: 2;
}
.container .answers .answer > span + span.button {
  display: block;
  width: 40px;
  height: 40px;
  background-color: transparent;
  -ms-flex-order: 1;
      order: 1;
  position: relative;
  border: 1px solid #717171;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-right: 15px;
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}
@media all and (min-width: 640px) {
  .container .answers .answer > span + span.button {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
}
.container .answers .answer > span + span.button:after {
  content: "";
  background-color: #fff;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.container .answers .answer.selected > span + span.button {
  border: 2px solid #79ada3;
}
.container .answers .answer.selected > span + span.button:after {
  background-color: #79ada3;
}
.container .answers .answer.correct > span + span.button {
  border: 2px solid #b0c831;
}
.container .answers .answer.correct > span + span.button:after {
  background-color: #b0c831;
}
.container .answers .answer.correct .quizAudioPlayButton,
.container .answers .answer.correct .quizAudioPauseButton {
  border: 0px solid #b0c831 !important;
  background: #b0c831 !important;
}
.container .answers .answer.correct .quizAudioPlayButton:before,
.container .answers .answer.correct .quizAudioPauseButton:before {
  background-image: url('../../static/images/icons/icon-speaker-active.svg') !important;
}
.container .answers .answer.incorrect > span + span.button {
  border: 2px solid #e20020;
}
.container .answers .answer.incorrect > span + span.button:after {
  background-color: #e20020;
}
.container .answers .answer.incorrect .quizAudioPlayButton,
.container .answers .answer.incorrect .quizAudioPauseButton {
  border: 0px solid #e20020 !important;
  background: #e20020 !important;
}
.container .answers .answer.incorrect .quizAudioPlayButton:before,
.container .answers .answer.incorrect .quizAudioPauseButton:before {
  background-image: url('../../static/images/icons/icon-speaker-active.svg') !important;
}
.container .answers .answer .quizAudioWrapper {
  max-width: 20%;
}
.container .answers .answer .quizAudioWrapper .quizAudioPlayButton,
.container .answers .answer .quizAudioWrapper .quizAudioPauseButton {
  border: 1px solid #717171;
  border-radius: 50%;
  background-color: transparent;
  width: 40px;
  height: 40px;
  min-width: 25px;
  padding: 5px;
  display: inline-block;
  margin: 0 15px 0 0;
  cursor: pointer;
}
@media all and (min-width: 640px) {
  .container .answers .answer .quizAudioWrapper .quizAudioPlayButton,
  .container .answers .answer .quizAudioWrapper .quizAudioPauseButton {
    width: 50px;
    height: 50px;
    min-width: 50px;
    margin: 0 20px 0 0;
  }
}
.container .answers .answer .quizAudioWrapper .quizAudioPlayButton:after,
.container .answers .answer .quizAudioWrapper .quizAudioPauseButton:after {
  display: none;
}
.container .answers .answer .quizAudioWrapper .quizAudioPlayButton:before,
.container .answers .answer .quizAudioWrapper .quizAudioPauseButton:before {
  background-image: url('../../static/images/icons/icon-speaker.svg');
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: 0;
  margin: -1px 0 0;
}
@media all and (min-width: 640px) {
  .container .answers .answer .quizAudioWrapper .quizAudioPlayButton:before,
  .container .answers .answer .quizAudioWrapper .quizAudioPauseButton:before {
    width: 39px;
    height: 39px;
    margin: 0;
  }
}
.container .answers .answer.selected .quizAudioPlayButton,
.container .answers .answer.selected .quizAudioPauseButton {
  border: 1px solid #79ada3;
  background: #79ada3;
}
.container .answers .answer.selected .quizAudioPlayButton:before,
.container .answers .answer.selected .quizAudioPauseButton:before {
  background-image: url('../../static/images/icons/icon-speaker-active.svg');
  background-color: transparent;
}
.container .answers .answer > span + span + span.button {
  display: none;
}
.container .answers .audio > span:first-child {
  -ms-flex: 0 0 auto !important;
      flex: 0 0 auto !important;
}
