.clozeContainer {
  line-height: 1.5;
  margin-bottom: 110px;
  padding: 0 0 20px;
  font-size: 18px;
}
@media all and (min-width: 640px) {
  .clozeContainer {
    font-size: 28px;
    line-height: 40px;
  }
}
.dropdownContainer[data-dropdownwrapper="left"] > div {
  left: 0 !important;
}
.dropdownContainer[data-dropdownwrapper="right"] > div {
  right: 0 !important;
}
.dropdownContainer .container {
  top: 28px;
  background: #7fada3;
  border-radius: 0 0 4px 4px;
}
@media all and (min-width: 640px) {
  .dropdownContainer .container {
    top: 41px;
  }
}
.dropdownContainer .container .answers {
  display: inline-block;
}
.dropdownContainer .container .answers .answer {
  color: #fff;
  margin: 0;
  padding: 5px 20px 5px 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-top: 1px solid #fff;
  width: 100%;
}
.dropdownContainer .container .answers .answer p {
  margin: 0;
}
.inputContainer input {
  background: #7fada3;
  border: none;
  padding: 2px;
  border-radius: 4px;
  font-size: 18px;
  max-width: 60px;
}
@media all and (min-width: 640px) {
  .inputContainer input {
    font-size: 28px;
    max-width: 200px;
  }
}
.inputContainer input:focus {
  background: #fff;
  color: #7fada3;
  -webkit-box-shadow: 0px 1px 2px 0px #7fada3;
          box-shadow: 0px 1px 2px 0px #7fada3;
}
.inputContainer:after {
  content: '';
  position: absolute;
  bottom: 5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  display: block;
  width: 90%;
  border-bottom: 1px solid #fff;
}
.inputContainer.filled input {
  background: #fff;
  color: #7fada3;
  -webkit-box-shadow: 0px 1px 2px 0px #7fada3;
          box-shadow: 0px 1px 2px 0px #7fada3;
}
.inputContainer.filled::after {
  display: none;
}
.inputContainer.correct .result {
  color: #b0c831;
}
.inputContainer.incorrect .result {
  color: #e20020;
}
.toggleDropdown {
  position: relative;
  width: 100%;
  background: #7fada3;
  border: none !important;
  border-radius: 4px;
  padding: 2px;
  font-size: 18px;
  color: #fff;
  text-align: left;
}
@media all and (min-width: 640px) {
  .toggleDropdown {
    font-size: 28px;
    min-width: 120px;
  }
}
.toggleDropdown:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 7px solid #fff;
}
.toggleDropdown.toggleSelected {
  min-width: 0 !important;
  background: #fff;
  color: #7fada3;
  -webkit-box-shadow: 0px 1px 2px 0px #7fada3;
          box-shadow: 0px 1px 2px 0px #7fada3;
  padding-right: 20px;
}
.toggleDropdown.toggleSelected:after {
  border-top: 7px solid #7fada3;
}
.toggleDropdown.dropdownFailed {
  color: #e20020 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  min-width: 0 !important;
  pointer-events: none;
  padding: 0;
}
.toggleDropdown.dropdownFailed:after {
  display: none;
}
.toggleDropdown.dropdownSucceeded {
  color: #b0c831 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  min-width: 0 !important;
  pointer-events: none;
  padding: 0;
}
.toggleDropdown.dropdownSucceeded:after {
  display: none;
}
