.menuPoint {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: end;
      justify-content: flex-end;
  position: relative;
  margin: 5px 0 5px 5px;
  padding: 15px;
  height: 112px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.menuPoint:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #000;
  opacity: 0.4;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.menuPoint:hover:after {
  opacity: 0;
}
.menuPoint:last-child {
  margin-right: 5px;
}
.menuPoint:first-child .buttonContainer .entry .pdfDownload:hover::after {
  left: -5px;
  bottom: -70px;
}
.headline {
  position: relative;
  z-index: 5;
  margin: 0;
  font-size: 14px;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.subline {
  position: relative;
  z-index: 5;
  margin: 0;
  font-size: 28px;
  line-height: 30px;
}
.moreContainer {
  position: absolute;
  bottom: 0;
  left: -5px;
  right: -5px;
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
  border: 5px solid #fff;
  border-width: 0 5px 5px 5px;
}
.informationContainer {
  padding: 15px;
  background: #79ada3;
}
@media all and (max-height: 900px) {
  .informationContainer {
    max-height: 200px;
    overflow: auto;
  }
}
@media all and (max-width: 1280px) {
  .informationContainer {
    max-height: 200px;
    overflow: auto;
  }
}
.description {
  margin-top: 20px;
}
.buttonContainer {
  position: absolute;
  left: -5px;
  right: -5px;
  bottom: 0;
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
  background-color: white;
  border: 5px solid #fff;
  border-width: 0 5px;
}
.entry {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}
.entry:last-child .button {
  border-bottom: none;
}
.entry:last-child .pdfDownload {
  border-bottom: none;
}
.pdfDownload {
  position: relative;
  width: 30px;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  text-decoration: none;
}
.pdfDownload:hover {
  background-color: #f5f5f5;
}
.pdfDownload:hover::after {
  position: absolute;
  z-index: 100;
  padding: 10px;
  left: -300px;
  width: 270px;
  content: "Hier können Sie Zusatzmaterialien für Ihren Unterricht herunterladen.";
  border-radius: 5px;
  color: #818181;
  background-color: #F6F6E5;
}
.button {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  width: 85%;
}
@media all and (max-width: 975px) {
  .subline {
    font-size: 20px;
  }
}
