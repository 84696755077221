.buttonWrapper {
  position: fixed;
  bottom: 20px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #79ada3;
  line-height: 60px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  z-index: 6;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.buttonWrapper:hover {
  background-color: #67948c;
}
.buttonWrapper:active {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  -webkit-transform: translateY(4px);
      -ms-transform: translateY(4px);
          transform: translateY(4px);
}
@media all and (min-width: 480px) {
  .buttonWrapper {
    width: 60px;
    height: 60px;
    line-height: 75px;
    bottom: 40px;
  }
}
@media all and (min-width: 640px) {
  .buttonWrapper {
    width: 80px;
    height: 80px;
    line-height: 105px;
  }
}
.buttonWrapper .buttonArrow {
  border: solid #fff;
  display: inline-block;
  border-width: 0 6px 6px 0;
  padding: 7px;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-right: 7px;
}
@media all and (min-width: 480px) {
  .buttonWrapper .buttonArrow {
    border-width: 0 7px 7px 0;
    padding: 9px;
  }
}
@media all and (min-width: 640px) {
  .buttonWrapper .buttonArrow {
    border-width: 0 9px 9px 0;
    padding: 12px;
  }
}
