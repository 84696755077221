.answer {
  position: relative;
  display: block;
  width: 100%;
  height: 200px;
  padding: 14px;
  text-align: center;
  border: 1px solid #979797;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.answer .button {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.answer:hover {
  border-color: #000;
}
.answer.selected {
  border: 3px solid #000;
}
.answer.correct {
  border: 5px solid #b0c831;
}
.answer.incorrect {
  border: 5px solid #e20020;
}
.answer span:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.answer img {
  height: 100%;
}
.container .answers {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-direction: row !important;
      flex-direction: row !important;
}
.container .answers .answer {
  width: calc(50% - 10px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.container .answers .answer:nth-child(even) {
  margin-left: 20px;
}
.container .answers .answer > span + span.button {
  display: none !important;
}
