.portraitStatus {
  position: relative;
  border-radius: 50%;
  margin-right: 40px;
  width: 35%;
  height: 35%;
  display: block;
  max-width: 280px;
  max-height: 280px;
  margin-top: 20px;
  cursor: pointer;
}
@media all and (min-width: 480px) {
  .portraitStatus {
    margin-right: 60px;
    margin-top: 50px;
    width: 30%;
    height: 30%;
  }
}
.portraitStatus:last-of-type {
  margin-right: 0;
}
.portraitStatus::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  right: 0;
  border: 5px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  opacity: 0;
}
@media all and (min-width: 480px) {
  .portraitStatus::after {
    border: 10px solid transparent;
  }
}
.portraitStatus.selected::after {
  border-color: #79ada3;
  opacity: 1;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.portraitStatus .portrait {
  display: inline;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
