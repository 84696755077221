.hotspot {
  position: absolute;
}
.hotspot .hoverArea {
  display: none;
}
.hotspot:hover {
  -webkit-animation: none;
          animation: none;
}
.hotspot:hover .hoverArea {
  display: block;
}
.hotspot .inner {
  position: absolute;
  left: -30px;
  width: 30px;
  height: 30px;
  opacity: 0.9;
  border: 10px solid #008cb3;
  -webkit-box-shadow: 0px 0px 15px 1px #008cb3;
          box-shadow: 0px 0px 15px 1px #008cb3;
  border-radius: 50%;
  -webkit-animation: pulsate 3s ease-out infinite;
          animation: pulsate 3s ease-out infinite;
  cursor: pointer;
}
.hotspot .inner:active,
.hotspot .inner:hover {
  border: 10px solid white;
  -webkit-box-shadow: 0px 0px 15px 1px white;
          box-shadow: 0px 0px 15px 1px white;
}
.hoverArea {
  position: absolute;
  top: 25px;
  left: 45px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 4px;
  background-color: white;
}
.hoverArea:hover + .inner {
  border: 10px solid white;
  -webkit-box-shadow: 0px 0px 15px 1px white;
          box-shadow: 0px 0px 15px 1px white;
}
.hoverArea::before {
  content: '';
  position: absolute;
  left: -90px;
  width: 90px;
  height: 100%;
}
.hoverArea::after {
  content: '';
  position: absolute;
  top: 50%;
  left: -9px;
  width: 0;
  height: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
}
.hoverArea .entry {
  padding-right: 35px;
  border-bottom: 4px solid white;
  background-color: #1e9ec1;
  color: white;
  white-space: nowrap;
}
.hoverArea .entry:hover {
  background-color: #33badf;
}
.hoverArea .entry:last-child {
  border-bottom: none;
}
.hoverArea .entry i {
  border-color: white;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.8;
  }
  45% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0.6;
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.8;
  }
  45% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0.6;
  }
}
