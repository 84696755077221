.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row;
      flex-flow: row;
  position: relative;
  z-index: 2;
}
.container:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.5;
}
.stripOnBottom:before {
  border-bottom: 1px solid #fff;
}
.dot {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  margin: 6px;
  opacity: 0.7;
  -webkit-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.dot.current {
  opacity: 1;
}
.bar {
  width: 100%;
  height: 5px;
  overflow: hidden;
  background-color: transparent;
}
.bar .fill {
  height: 100%;
  background-color: #717171;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.count {
  position: absolute;
  top: 7px;
  right: 20px;
  font-size: 13px;
  color: #fff;
}
