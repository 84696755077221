.inputWrapper {
  margin-top: 30px;
}
@media all and (min-width: 480px) {
  .inputWrapper {
    margin-top: 50px;
  }
}
.inputWrapper .inputLabel {
  color: #717171;
  font-weight: 500;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.inputWrapper .inputLabelText {
  color: #96999f;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  font-family: 'OpenSans-Bold';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media all and (min-width: 480px) {
  .inputWrapper .inputLabelText {
    font-size: 24px;
    margin-bottom: 15px;
  }
}
.inputWrapper .inputField {
  width: 100%;
  height: 50px;
  border-top: 6px;
  border: 1px solid #d3d3d3;
  color: #717171;
  border-radius: 6px;
  font-size: 28px;
  padding: 0 10px;
  text-align: center;
  background-color: #f6f6f6;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media all and (min-width: 480px) {
  .inputWrapper .inputField {
    height: 100px;
    font-size: 32px;
  }
}
