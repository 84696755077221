.message {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}
.message.thought {
  background-color: #98b7b1 !important;
}
.message.thought:after,
.message.thought:before {
  left: auto;
  right: -3px;
  background-color: #98b7b1 !important;
}
.message.thought:before {
  right: -4px;
}
.message .avatar {
  position: absolute;
  left: auto;
  margin-left: 2px;
  right: -51px;
  bottom: -20px;
}
