@font-face {
  font-family: 'OpenSans';
  src: url('../../static/fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../../static/fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Light';
  src: url('../../static/fonts/OpenSans-Light.ttf') format('truetype');
}
body {
  font-family: 'OpenSans', Arial, Helvetica, sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -ms-user-select: none;
}
strong {
  font-family: 'OpenSans-Bold';
}
*:focus {
  outline: 0;
}
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 55px 20px 90px;
  overflow: auto;
  background: #7fada3;
  color: #fff;
}
@media all and (min-width: 480px) {
  .container {
    padding-bottom: 105px 40px 135px;
  }
}
@media all and (min-width: 640px) {
  .container {
    padding-bottom: 160px;
  }
}
.container .subline,
.container .duration {
  font-size: 15px;
  letter-spacing: 3px;
  line-height: 26px;
  font-weight: normal;
  font-family: 'OpenSans-Light';
  margin-bottom: 50px;
  display: inline-block;
}
@media all and (min-width: 480px) {
  .container .subline,
  .container .duration {
    font-size: 32px;
    letter-spacing: 4px;
    line-height: 38px;
    margin-bottom: 60px;
  }
}
.container .headline {
  width: 100%;
  font-size: 28px;
  margin-top: 0;
  font-family: 'OpenSans-Bold';
  font-weight: 200;
  letter-spacing: 1.4px;
  line-height: 1.15;
  margin-bottom: 20px;
}
@media all and (min-width: 480px) {
  .container .headline {
    font-size: 56px;
    max-width: 720px;
  }
}
.container .text {
  letter-spacing: 0;
  font-family: 'OpenSans-Light', Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 28px;
}
@media all and (min-width: 480px) {
  .container .text {
    font-size: 24px;
    line-height: 30px;
  }
}
@media all and (min-width: 640px) {
  .container .text {
    font-size: 32px;
    line-height: 46px;
  }
}
.container .duration {
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0;
  margin-top: 10px;
  background-color: #7fada3;
  padding: 20px;
}
@media all and (min-width: 480px) {
  .container .duration {
    height: 80px;
    line-height: 80px;
    padding: 32px 20px;
  }
}
@media all and (min-width: 640px) {
  .container .duration {
    padding: 40px 20px;
  }
}
