.buttonWrapper {
  left: 20px;
}
@media all and (min-width: 480px) {
  .buttonWrapper {
    left: 40px;
  }
}
.buttonWrapper .buttonArrow {
  -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
          transform: rotate(135deg);
  margin-left: 7px;
  margin-right: 0;
}
@media all and (min-width: 480px) {
  .buttonWrapper .buttonArrow {
    margin-left: 10px;
  }
}
