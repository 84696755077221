.slide {
  padding-bottom: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.slide h2 {
  font-family: 'OpenSans-Bold';
  font-size: 20px;
  line-height: 1.5;
  margin: 0;
  color: #202020;
}
@media all and (min-width: 480px) {
  .slide h2 {
    font-size: 32px;
  }
}
@media all and (min-width: 640px) {
  .slide h2 {
    font-size: 40px;
  }
}
.slide p {
  font-size: 16px;
  line-height: 1.4;
  margin: 10px 0;
  color: #202020;
  margin-bottom: 80px;
}
@media all and (min-width: 480px) {
  .slide p {
    font-size: 24px;
  }
}
@media all and (min-width: 640px) {
  .slide p {
    font-size: 32px;
    line-height: 44px;
  }
}
.slide img {
  width: 100%;
}
.slide .hotspot {
  left: 50%;
  margin-top: -40px;
}
.slide .hotspot span {
  width: 15px !important;
  height: 15px !important;
  left: -20px !important;
  top: -10px !important;
}
.scrollIndicatorContainer {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  background: -o-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  min-height: 100px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
      align-items: flex-end;
  margin: 0 3px;
  margin-top: -40px;
}
@media all and (min-width: 580px) {
  .scrollIndicatorContainer {
    min-height: 140px;
  }
}
.scrollIndicatorContainer .scrollIndicator {
  display: block;
  max-height: 60px;
  bottom: -10px;
  position: relative;
  margin: 0 auto;
}
@media all and (min-width: 580px) {
  .scrollIndicatorContainer .scrollIndicator {
    max-height: 100px;
  }
}
.button {
  margin-top: -40px !important;
  padding: 4px !important;
  background: #fff !important;
  border: 2px solid #1e9ec1 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.center {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
