.buttonWrapper {
  background-color: #fff;
}
.buttonWrapper:hover {
  background-color: #f9f9f9;
}
.buttonWrapper .buttonArrow {
  border: solid #79ada3;
  border-width: 0 6px 6px 0;
}
@media all and (min-width: 480px) {
  .buttonWrapper .buttonArrow {
    border-width: 0 7px 7px 0;
    padding: 9px;
  }
}
@media all and (min-width: 640px) {
  .buttonWrapper .buttonArrow {
    border-width: 0 9px 9px 0;
    padding: 12px;
  }
}
