.message {
  color: #79ada3 !important;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
}
.message p {
  margin: 0 auto;
  text-align: center !important;
}
.message:after {
  display: none;
}
