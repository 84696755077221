.container {
  padding: 80px 20px 0;
}
@media all and (min-width: 480px) {
  .container {
    padding: 100px 40px 0;
  }
}
.container .welcomeTitle {
  color: #717171;
  width: 100%;
  font-size: 28px;
  margin-top: 0;
  font-weight: 200;
  font-family: 'OpenSans-Bold';
  letter-spacing: 1.4px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media all and (min-width: 640px) {
  .container .welcomeTitle {
    font-size: 56px;
    max-width: 540px;
    margin-bottom: 30px;
  }
}
.container .portraitWrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.container .charachterSelectError {
  color: #e24056;
  text-align: center;
  max-width: 560px;
  margin: 0 auto;
  margin-top: 25px;
  font-family: 'OpenSans-Light';
  font-size: 16px;
  line-height: 22px;
}
@media all and (min-width: 480px) {
  .container .charachterSelectError {
    font-size: 24px;
    line-height: 36px;
  }
}
@media all and (min-width: 640px) {
  .container .charachterSelectError {
    font-size: 32px;
    line-height: 44px;
    margin-top: 60px;
  }
}
