.questionSet {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
}
.scrollContainer {
  padding: 60px 20px 0 20px;
  margin-bottom: 20px;
  -ms-flex-positive: 1;
      flex-grow: 1;
  overflow: auto;
}
.questionWrapper h4 {
  margin: 0;
  margin-bottom: 20px;
  font-family: 'OpenSans-Bold';
  font-size: 20px;
}
@media all and (min-width: 640px) {
  .questionWrapper h4 {
    font-size: 36px;
  }
}
@media all and (min-width: 640px) {
  .questionWrapper span p,
  .questionWrapper span span {
    font-size: 28px;
  }
}
.footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-align: center;
      align-items: center;
  margin-top: auto;
  padding-left: 15px;
  background-color: #f9f9f9;
}
.counter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-positive: 1;
      flex-grow: 1;
  padding: 20px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #999999;
}
.next {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-positive: 0;
      flex-grow: 0;
  width: 214px;
  background-color: #79ada3;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.next:hover {
  cursor: pointer;
}
.next.disabled {
  background-color: #e7e7e7;
  color: #ccc;
}
.next.disabled:hover {
  cursor: not-allowed;
}
.feedback {
  color: #717171;
  font-size: 18px;
}
@media all and (min-width: 640px) {
  .feedback {
    font-size: 28px;
    line-height: 40px;
  }
}
.feedback.correct {
  background: #b0c831;
  color: #fff;
  border-radius: 7px;
  padding: 5px 10px;
  margin-top: 20px;
}
.feedback.partiallyCorrect {
  background: #f19314;
  color: #fff;
  border-radius: 7px;
  padding: 5px 10px;
  margin-top: 20px;
}
.feedback.incorrect {
  background: #e20020;
  color: #fff;
  border-radius: 7px;
  padding: 5px 10px;
  margin-top: 20px;
}
.feedback strong {
  color: #b0c831;
}
