.buttonHintContent:after {
  right: 12px;
  display: block !important;
}
@media all and (min-width: 480px) {
  .buttonHintContent:after {
    right: 31px;
  }
}
@media all and (min-width: 640px) {
  .buttonHintContent:after {
    right: 34px;
  }
}
