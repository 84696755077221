.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  width: 100px;
}
.dot {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  position: relative;
  height: 15px;
  width: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 50%;
  background-color: #a5c5be;
  -webkit-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.dot.active {
  background-color: #67948c;
}
.clickable .dot:hover {
  cursor: pointer;
  background-color: #67948c;
}
