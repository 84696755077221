.info {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 10px;
  padding-bottom: 10px;
  background: #fff;
  overflow-y: auto;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 90px;
}
@media all and (min-width: 480px) {
  .info {
    margin-bottom: 135px;
  }
}
@media all and (min-width: 640px) {
  .info {
    margin-bottom: 160px;
  }
}
.info h1,
.info p {
  padding-left: 10px;
}
.info h1 {
  margin-bottom: 30px;
  color: #717171;
  font-family: 'OpenSans-Bold';
}
