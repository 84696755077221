@font-face {
  font-family: 'OpenSans';
  src: url('../static/fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../static/fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Light';
  src: url('../static/fonts/OpenSans-Light.ttf') format('truetype');
}
body {
  font-family: 'OpenSans', Arial, Helvetica, sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -ms-user-select: none;
}
strong {
  font-family: 'OpenSans-Bold';
}
*:focus {
  outline: 0;
}
