@font-face {
  font-family: 'OpenSans';
  src: url('../../static/fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../../static/fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Light';
  src: url('../../static/fonts/OpenSans-Light.ttf') format('truetype');
}
body {
  font-family: 'OpenSans', Arial, Helvetica, sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -ms-user-select: none;
}
strong {
  font-family: 'OpenSans-Bold';
}
*:focus {
  outline: 0;
}
.room {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  background-image: url('../../static/images/bg-pattern.png');
  background-repeat: repeat;
}
.room.fixed {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
}
.backgroundImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.sceneElement {
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.scrollArea {
  position: absolute;
}
.scrollIndicatorContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
  height: 20%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  background: -o-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
.scrollIndicatorContainer .scrollIndicator {
  display: block;
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin: 0 auto;
}
.button {
  padding: 4px !important;
  background: #fff !important;
  border: 2px solid #1e9ec1 !important;
}
.button:hover {
  background: #f9f9f9 !important;
}
.glossaryButton {
  top: 10px;
}
@media all and (min-width: 480px) {
  .glossaryButton {
    top: 20px;
  }
}
@media all and (min-width: 640px) {
  .glossaryButton {
    top: 30px;
  }
}
.infoBoxButton {
  top: 70px;
}
@media all and (min-width: 480px) {
  .infoBoxButton {
    top: 100px;
  }
}
@media all and (min-width: 640px) {
  .infoBoxButton {
    top: 130px;
  }
}
.infoButton {
  top: 130px;
}
@media all and (min-width: 480px) {
  .infoButton {
    top: 180px;
  }
}
@media all and (min-width: 640px) {
  .infoButton {
    top: 230px;
  }
}
.restartButton {
  top: 10px;
  left: auto !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border: 5px solid white !important;
  background: #79ada3 !important;
}
@media all and (min-width: 480px) {
  .restartButton {
    top: 20px;
  }
}
@media all and (min-width: 640px) {
  .restartButton {
    top: 30px;
  }
}
.restartButton:hover {
  background: #5c978c !important;
  border: 5px solid #e0e0e0 !important;
}
.restartButton svg {
  width: 80%;
}
.restartContainer {
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
}
.restartContainer .innerContainer {
  position: fixed;
  z-index: 20;
  background: #fff;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 25px;
  width: calc(100% - 40px);
  max-width: 580px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.restartContainer .restartButtonsHolder {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.restartContainer .restartButtons {
  width: 45%;
  padding: 9px 5px;
  background-color: #79ada3;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border: 1px solid transparent;
}
.restartContainer .restartButtons:hover {
  color: #79ada3;
  background-color: #fff;
  border: 1px solid #79ada3;
}
.exerciseSetContainer {
  padding-top: 40px;
  position: fixed;
  background-color: #79ada3;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding-bottom: 80px;
}
.exerciseSetContainer:after {
  content: "";
  position: fixed;
  left: -20px;
  width: calc(100% + 40px);
  bottom: 14px;
  height: 40px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(121, 173, 163, 0)), to(#79ada3));
  background: -webkit-linear-gradient(top, rgba(121, 173, 163, 0), #79ada3);
  background: -o-linear-gradient(top, rgba(121, 173, 163, 0), #79ada3);
  background: linear-gradient(to bottom, rgba(121, 173, 163, 0), #79ada3);
  z-index: 1;
  -webkit-appearance: none;
}
.exerciseSetContainer:before {
  position: fixed;
  bottom: 0;
  left: -20px;
  width: calc(100% + 40px);
  content: '';
  background: #79ada3;
  height: 15px;
  z-index: 2;
}
.exerciseSetContainer p,
.exerciseSetContainer h2 {
  padding: 0 15px;
  color: #fff;
}
.exerciseSetContainer p {
  margin-bottom: 0;
  letter-spacing: 2px;
}
.exerciseSetContainer h2 {
  margin-top: 5px;
  margin-bottom: 40px;
  font-family: 'OpenSans-Bold';
  font-size: 32px;
}
.infoText {
  margin-bottom: 12px;
}
.infoEntry {
  font-family: 'OpenSans-Bold';
}
.pulse {
  -webkit-box-shadow: 0 0 0 #0fd1df;
          box-shadow: 0 0 0 #0fd1df;
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #0fd1df;
            box-shadow: 0 0 0 0 #0fd1df;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(15, 209, 223, 0);
            box-shadow: 0 0 0 10px rgba(15, 209, 223, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(15, 209, 223, 0);
            box-shadow: 0 0 0 0 rgba(15, 209, 223, 0);
  }
}
@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #0fd1df;
            box-shadow: 0 0 0 0 #0fd1df;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(15, 209, 223, 0);
            box-shadow: 0 0 0 10px rgba(15, 209, 223, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(15, 209, 223, 0);
            box-shadow: 0 0 0 0 rgba(15, 209, 223, 0);
  }
}
