.popUp {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  border-radius: 0;
  padding-top: 50px;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.popUp h1 {
  color: #666666;
  font-size: 26px;
}
.popUp p {
  color: #666666;
  font-size: 18px;
  font-family: 'OpenSans', Arial, Helvetica, sans-serif;
}
.closeButton {
  display: none !important;
}
