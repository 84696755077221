.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 50;
  padding: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.container .descriptionWrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  overflow: auto;
  padding-bottom: 10px;
}
.container .descriptionWrapper .icon {
  margin: 20px 0;
  width: 150px;
  height: 150px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border: 2px solid rgba(175, 208, 202, 0.9);
  border-radius: 100%;
  position: relative;
}
@media all and (min-width: 480px) {
  .container .descriptionWrapper .icon {
    width: 200px;
    height: 200px;
    border: 4px solid rgba(175, 208, 202, 0.9);
  }
}
.container .descriptionWrapper .icon.iconCorrect:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-left: 13px solid #78ada5;
  border-bottom: 13px solid #78ada5;
  width: 43%;
  height: 23%;
  -webkit-transform: translate(-50%, -80%) rotate(-45deg);
      -ms-transform: translate(-50%, -80%) rotate(-45deg);
          transform: translate(-50%, -80%) rotate(-45deg);
}
@media all and (min-width: 480px) {
  .container .descriptionWrapper .icon.iconCorrect:before {
    border-left: 19px solid #78ada5;
    border-bottom: 19px solid #78ada5;
  }
}
@media all and (min-width: 640px) {
  .container .descriptionWrapper .icon.iconCorrect:before {
    border-left: 24px solid #78ada5;
    border-bottom: 24px solid #78ada5;
  }
}
.container .descriptionWrapper .icon.iconWrong:before,
.container .descriptionWrapper .icon.iconWrong:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 12px;
  background-color: #78ada5;
}
@media all and (min-width: 480px) {
  .container .descriptionWrapper .icon.iconWrong:before,
  .container .descriptionWrapper .icon.iconWrong:after {
    height: 16px;
  }
}
@media all and (min-width: 640px) {
  .container .descriptionWrapper .icon.iconWrong:before,
  .container .descriptionWrapper .icon.iconWrong:after {
    height: 20px;
  }
}
.container .descriptionWrapper .icon.iconWrong:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
}
.container .descriptionWrapper .icon.iconWrong:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}
.container .descriptionWrapper .description {
  margin: 10px 0;
  text-align: center;
  color: #79ada3;
  font-size: 18px;
  line-height: 30px;
  font-weight: bold;
  padding: 0 10px;
  font-family: 'OpenSans-Bold';
}
@media all and (min-width: 480px) {
  .container .descriptionWrapper .description {
    font-size: 26px;
    line-height: 50px;
    padding: 0 25px;
  }
}
@media all and (min-width: 640px) {
  .container .descriptionWrapper .description {
    font-size: 36px;
    line-height: 60px;
    padding: 0 40px;
  }
}
.container .disabled {
  background-color: #e7e7e7 !important;
  color: #ccc !important;
}
.container .disabled:hover {
  cursor: not-allowed;
}
.container .button {
  background-color: #7fada3;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative;
  z-index: 2000;
  border-radius: 4px;
  padding: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 16px;
  color: #fff;
  -webkit-box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.container .button:active,
.container .button:hover {
  background-color: #67948c;
}
@media all and (min-width: 480px) {
  .container .button span {
    font-size: 24px;
  }
}
@media all and (min-width: 480px) {
  .container .button span {
    font-size: 28px;
  }
}
.container .spacing {
  margin-bottom: 20px;
}
.infoContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  color: #666666;
}
@media all and (min-width: 480px) {
  .infoContainer {
    font-size: 20px;
  }
}
.infoContainer svg {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.infoUnlocked {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.infoLink {
  margin: 10px 0;
  font-weight: 600;
  text-decoration: underline;
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
  text-underline-position: under;
  cursor: pointer;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.infoLink:hover {
  color: #b3b3b3;
}
.infoLink:last-child {
  margin-bottom: 30px;
}
