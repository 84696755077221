.container {
  padding-left: 10px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 90px;
}
@media all and (min-width: 480px) {
  .container {
    margin-bottom: 135px;
  }
}
@media all and (min-width: 640px) {
  .container {
    margin-bottom: 160px;
  }
}
.title {
  padding-left: 10px;
  margin-bottom: 30px;
  color: #717171;
  font-family: 'OpenSans-Bold';
}
.backButton {
  z-index: 100000000;
  position: relative;
}
.content {
  font-family: 'OpenSans-Bold';
}
.content .letterGroup {
  margin: 30px 0 0;
  border-bottom: 2px solid #d3d3d3;
  padding-left: 10px;
  padding-bottom: 5px;
  font-size: 22px;
  color: #717171;
  display: block;
  text-transform: uppercase;
}
.content .word {
  margin: 0;
  border-bottom: 1px solid #96999f;
  padding: 15px 30px 15px 0;
  padding-left: 10px;
  font-size: 18px;
  color: #79ada3;
  position: relative;
  display: block;
  cursor: pointer;
}
.content .word:before,
.content .word:after {
  border-right: 2px solid;
  content: '';
  display: block;
  height: 10px;
  margin-top: -6px;
  position: absolute;
  -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
          transform: rotate(135deg);
  right: 20px;
  top: 50%;
  width: 0;
  color: #d3d3d3;
}
.content .word:after {
  margin-top: 0px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.content .word:hover {
  color: #f9f9f9;
  background-color: #79ada3;
}
.content div:last-of-type .word {
  border-bottom: none;
}
