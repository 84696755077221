.notificationContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  -webkit-animation: fadein 1s;
          animation: fadein 1s;
  background: rgba(0, 0, 0, 0.8);
}
.notificationContainer .notification {
  position: relative;
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 4px;
  padding: 20px;
  background: #fff;
  color: #717171;
  text-align: center;
}
.notificationContainer .closeButton {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  overflow: hidden;
  top: 10px;
  right: 10px;
}
.notificationContainer .closeButton:hover {
  background: #bababa;
}
.notificationContainer .closeButton::before,
.notificationContainer .closeButton::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #67948c;
}
.notificationContainer .closeButton::before {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.notificationContainer .closeButton::after {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
