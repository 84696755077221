.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 100;
}
.exerciseTrigger {
  display: block;
  padding: 15px 50px 15px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #fff;
  cursor: pointer;
}
.exerciseTrigger:hover {
  color: #e6e6e6;
}
.exerciseTrigger:hover .exerciseIcon {
  background-color: #e6e6e6;
}
.exerciseTrigger .exerciseIcon {
  margin-right: 15px;
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  min-width: 50px;
  height: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -webkit-box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
}
.exerciseTrigger .exerciseIcon svg {
  position: relative;
}
.exerciseTrigger .coin1 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 8px;
  border-radius: 50%;
  background-color: #fff;
  z-index: -1;
  -webkit-box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
}
.exerciseTrigger .coin2 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 16px;
  border-radius: 50%;
  background-color: #fff;
  z-index: -2;
  -webkit-box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
}
.exerciseTrigger .coin3 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 24px;
  border-radius: 50%;
  background-color: #fff;
  z-index: -3;
  -webkit-box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
}
.exerciseTrigger.oneChained .exerciseIcon {
  position: relative;
  top: -4px;
}
.exerciseTrigger.twoChained .exerciseIcon {
  position: relative;
  -webkit-box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
  top: -8px;
}
.exerciseTrigger.threeChained .exerciseIcon {
  position: relative;
  -webkit-box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.25);
  top: -12px;
}
.exerciseTrigger.finished {
  position: relative;
}
.exerciseTrigger.finished:after {
  position: absolute;
  content: '';
  width: calc(100% - 15px);
  height: 96%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 2%;
  left: 7px;
  border-radius: 10px;
}
