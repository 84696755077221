.buttonHintContent:after {
  left: 12px;
  display: block !important;
}
@media all and (min-width: 480px) {
  .buttonHintContent:after {
    left: 31px;
  }
}
@media all and (min-width: 640px) {
  .buttonHintContent:after {
    lefts: 34px;
  }
}
