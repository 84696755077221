.button {
  position: relative;
  cursor: pointer;
  padding: 15px 15px 15px 10px;
  background: #fff;
  color: #79ada3;
  font-size: 18px;
  font-weight: 800;
  line-height: 1;
}
.button:hover {
  background: #f5f5f5;
}
.button:hover .tooltipText {
  opacity: 1;
}
.buttonArrow {
  position: absolute;
  right: 2px;
  top: 50%;
  -webkit-transform: rotate(-45deg) translateY(-50%);
      -ms-transform: rotate(-45deg) translateY(-50%);
          transform: rotate(-45deg) translateY(-50%);
  border: solid #79ada3;
  display: inline-block;
  border-width: 0 2px 2px 0;
  padding: 4px;
}
.tooltipText {
  position: absolute;
  top: 30%;
  left: 100%;
  padding: 20px;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  z-index: 1;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  -ms-flex-flow: column;
      flex-flow: column;
  border-radius: 5px;
  color: #818181;
  font-size: 14px;
  background-color: #F6F6E5;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}
.tooltipText:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  margin-left: -11px;
  border: 13px solid transparent;
  border-right-color: #F6F6E5;
  border-left: 0;
  border-bottom: 0;
}
.tooltipText .icon {
  margin-top: -15px;
  margin-left: -10px;
  padding-bottom: 5px;
  height: 25px;
  width: 25px;
}
