.audioWrapper {
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.audioWrapper .audioPlay {
  border: 1px solid #717171;
  border-radius: 50%;
  background: transparent;
  width: 40px;
  height: 40px;
  min-width: 25px;
  padding: 5px;
  display: inline-block;
  margin: 0 15px 0 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.audioWrapper .audioPlay.playing {
  border: 1px solid #79ada3;
  background: #79ada3;
}
.audioWrapper .audioPlay.playing svg {
  fill: #fff;
}
@media all and (min-width: 640px) {
  .audioWrapper .audioPlay {
    width: 50px;
    height: 50px;
    min-width: 50px;
    margin: 0 20px 0 40px;
  }
}
.audioWrapper .audioText {
  display: inline-block;
  color: #717171;
  padding-right: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media all and (min-width: 640px) {
  .audioWrapper .audioText {
    font-size: 28px;
    line-height: 40px;
  }
}
.audioWrapper .audioPlayer {
  display: none;
}
