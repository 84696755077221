.message {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
      flex-flow: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-item-align: start;
      align-self: flex-start;
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: relative;
  max-width: 90%;
  padding: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #717171;
  line-height: 1.4;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 40px;
  font-family: 'Tele-GroteskNor', Arial, sans-serif;
  -webkit-animation: receiveMessage 350ms;
          animation: receiveMessage 350ms;
}
@media all and (min-width: 480px) {
  .message {
    padding: 25px;
  }
}
@media all and (min-width: 640px) {
  .message {
    padding: 30px;
  }
}
.message:after {
  content: '';
  bottom: -8px;
  left: -37.3px;
  position: absolute;
  border: 0px solid;
  display: block;
  width: 38px;
  height: 26px;
  background-color: transparent;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 60%;
  -webkit-box-shadow: -16px -5px 0px -6px #fff;
          box-shadow: -16px -5px 0px -6px #fff;
  -webkit-transform: rotate(171deg);
      -ms-transform: rotate(171deg);
          transform: rotate(171deg);
}
.message.thought {
  border-radius: 15px;
  font-style: italic;
  color: #666666 !important;
  background-color: #d1d1d1 !important;
}
.message.thought:after,
.message.thought:before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #d1d1d1 !important;
  border-radius: 50%;
  position: absolute;
  left: -3px;
  bottom: -2px;
}
.message.thought:before {
  width: 4px;
  height: 4px;
  left: -4px;
  bottom: -6px;
}
.message p {
  float: left;
  margin: 0;
}
.message p span > span {
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
  text-underline-position: under;
}
.message .headline {
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
}
.message .avatar {
  position: absolute;
  left: -53px;
  bottom: -20px;
  display: block;
  width: 40px;
  height: 40px;
  margin-left: 2px;
  border-radius: 50%;
  background-size: cover;
  overflow: hidden;
}
.message .avatar:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0px;
}
@-webkit-keyframes receiveMessage {
  from {
    top: 10px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@keyframes receiveMessage {
  from {
    top: 10px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
