.message {
  color: #fff !important;
  background-color: #79ada3 !important;
}
.message:after {
  bottom: -7px;
  right: -38px;
  left: auto;
  -webkit-box-shadow: -16px -5px 0px -6px #79ada3;
          box-shadow: -16px -5px 0px -6px #79ada3;
  -webkit-transform: rotate(6deg) rotateX(180deg);
          transform: rotate(6deg) rotateX(180deg);
}
