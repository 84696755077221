.menu {
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 20px;
  left: 120px;
  right: 120px;
  z-index: 10;
  background-color: #fff;
  color: #fff;
}
