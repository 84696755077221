.table tbody > tr > td > svg {
  height: 80px;
}
.table .center {
  text-align: center;
}
.fakeButton {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  background-color: #79ada3;
}
.fakeButton svg {
  width: 70%;
}
.button {
  padding: 4px !important;
  background: #fff !important;
  border: 2px solid #1e9ec1 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
